import { IconContext } from "react-icons";
import { BsEyeFill as Eye, BsEyeSlashFill as SlashEye } from "react-icons/bs";

export default function PasswordField({ className, ...restProps }) {
  const _passwordtype =
    restProps?.passwordtype === "true" ? "password" : "text";
  return (
    <div className="relative w-[300px] h-[46px] sm:w-[350px] sm:h-[50px] md:w-[370px] md:h-[54px] lg:w-[370px] lg:h-[56px]">
      <input
        {...restProps}
        name={restProps?.name}
        type={_passwordtype}
        className={`focus:border focus:border-primaryblue placeholder-lightgray placeholder:font-ptsans placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-full p-2.5 ${className}`}
        // className={`border-2 py-2 pl-4 pr-14 rounded-lg w-full outline-none ${className}`}
      />

      <IconContext.Provider
        value={{
          className: `text-lg absolute right-6 top-[17px] cursor-pointer`,
        }}
      >
        {restProps.passwordtype === "true" ? (
          <SlashEye
            onClick={() =>
              restProps?.setpasswordtype(
                restProps?.passwordtype === "true" ? "false" : "true"
              )
            }
          />
        ) : (
          <Eye
            onClick={() =>
              restProps?.setpasswordtype(
                restProps?.passwordtype === "false" ? "true" : "false"
              )
            }
          />
        )}
      </IconContext.Provider>
    </div>
  );
}
