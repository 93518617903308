import React from "react";
import Section1 from "../sections/Login/Section1";

const Login = () => {

  return (
    <>
     <Section1/>
    </>
  );
};

export default Login;
