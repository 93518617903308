import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import CircularButton from "../../components/common/MainButton/CircularButton";
import PasswordField from "../../components/common/PasswordField/PasswordField";
import Heading from "../../components/common/Typography/Heading";
import TextFiled from "../../components/common/InputField/TextFiled";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import MainButton from "../../components/common/MainButton/MainButton";
import { Link } from "react-router-dom";
import Paragraph from "../../components/common/Typography/Paragraph";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";

const Section1 = () => {
  const [step, setStep] = useState(1);
  const [selectedRole, setSelectedRole] = useState(null);
  const roles = [
    { name: "Owner / CEO" },
    { name: "System Administrator / Technical Lead" },
    { name: "Frontend Developer / UI-UX Designer" },
    { name: "Content Manager / SEO Specialist" },
    { name: "Customer Support / Quality Assurance" },
    { name: "Data Analyst / Financial Reporting" },
    { name: "Legal / Compliance Advisor" },
  ];
  return (
    <>
      {step === 1 && (
        <>
          <div className={`bg-white fixed w-screen h-screen hidden-scrollbar`}>
            <div className="flex items-center justify-between max-h-[120px] mt-10 mx-[5%] xl:w-[1400px] xl:mx-auto">
              <div>
                <Link to={"https://dev.tradesposter.com/"} target="_blank">
                  <img
                       className="w-[60px] h-[25px] sm:w-[65px] sm:h-[28px] lg:w-[70px] lg:h-[30px] cursor-pointer"
                    src={require("../../assets/img/trades-poster.png")}
                    alt="trade-poster"
                  />
                </Link>
              </div>
              {/* <div className="m-5">
            <div>
              <Link to={"/AdminPanel/Dashboard"}>
                <CircularButton variant={"cross"}></CircularButton>
              </Link>
            </div>
          </div> */}
            </div>
            <div className="lg:mt-40">
              <Heading
                variant={"h4"}
                className={"flex items-center justify-center mb-20"}
              >
                Admin portal
              </Heading>
              <div
                id="#overflow"
                className="h-[calc(100vh_-_120px)] overflow-y-auto max-w-[650px] rounded-lg fixed left-1/2 -translate-x-1/2 hidden-scrollbar pb-[600px]"
              >
                <div className="max-w-[650px] bg-white mx-auto">
                  <form>
                    <div className="flex justify-center">
                      <div>
                        <TextFiled
                          label={"Email"}
                          variant={"medium"}
                          className={"bg-pastalblue"}
                          placeholder={"Enter your email"}
                        />
                      </div>
                    </div>
                    <div className="my-5 flex justify-center">
                      <div>
                        <label className="mb-2 block">
                        <AuxiliaryText variant={"FieldLabel"} className={"text-offblack"}>
                            Password
                          </AuxiliaryText>
                        </label>
                        <PasswordField className={"bg-pastalblue"} placeholder={"Enter your password"}/>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <div>
                        <label className="mb-2 block">
                        <AuxiliaryText variant={"FieldLabel"} className={"text-offblack"}>
                            User roles
                          </AuxiliaryText>
                        </label>
                        <Dropdown
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.value)}
                          options={roles}
                          optionLabel="name"
                          placeholder="Select a role"
                          className="bg-pastalblue w-[300px] h-[46px] sm:w-[350px] sm:h-[50px] md:w-[370px] md:h-[54px] lg:w-[370px] lg:h-[56px] px-2 rounded-lg flex items-center text-offblack"
                        />
                      </div>
                    </div>
                    <div className="flex justify-center mt-10">
                      <Link to={"/admin-panel/dashboard"}>
                        <MainButton
                          variant={"large"}
                          // onClick={() => setStep(2)}
                        >
                          Sign in
                        </MainButton>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div className={`bg-white fixed w-screen h-screen hidden-scrollbar`}>
            <div className="flex items-center justify-between max-h-[120px] mt-10 mx-[10%]">
              <div>
                <Link to={"https://dev.tradesposter.com/"} target="_blank">
                  <img
                    className="w-[80px] h-[35px] cursor-pointer"
                    src={require("../../assets/img/trades-poster.png")}
                    alt="trade-poster"
                  />
                </Link>
              </div>
              {/* <div className="m-5">
            <div>
              <Link to={"/AdminPanel/Dashboard"}>
                <CircularButton variant={"cross"}></CircularButton>
              </Link>
            </div>
          </div> */}
            </div>
            <div className="lg:mt-40">
              <Heading
                variant={"h4"}
                className={"flex items-center justify-center mb-20"}
              >
                Verify your identity
              </Heading>
              <div className="flex justify-center max-w-[350px] mx-auto mb-10">
                <Paragraph variant={"Main"} className={"text-center"}>
                  Please visit your registered email inbox and find out the
                  verification code receievd from tradesposter
                </Paragraph>
              </div>
              <div
                id="#overflow"
                className="h-[calc(100vh_-_120px)] overflow-y-auto max-w-[650px] rounded-lg fixed left-1/2 -translate-x-1/2 hidden-scrollbar pb-[600px]"
              >
                <div className="max-w-[650px] bg-white mx-auto px-1">
                  <form>
                    <div className="flex justify-center">
                      <div>
                        <TextFiled
                          label={"Verification code"}
                          placeholder={"Please enter your verification code"}
                          variant={"small"}
                        />
                      </div>
                    </div>
                    <div className="flex justify-center mt-10">
                      <Link to={"/admin-panel/dashboard"}>
                        <MainButton variant={"large"}>Verify</MainButton>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Section1;
