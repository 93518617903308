
import React from 'react'
import Heading from '../../components/common/Typography/Heading';

const Dashboard = () => {


  return (
    <>
        <Heading variant={"h1"} className={"text-center"}>
        Tradesposter
      </Heading>
  </>
  )
}

export default Dashboard;
