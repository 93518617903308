import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";

const Layout = () => {
  const location = useLocation();
  // console.log("roles", auth?.roles[0]?.roleName)
  if (location?.pathname?.includes("admin-panel")) {
    return (
      <main className="App grid grid-rows-[0fr,_1fr]">
        {/* <Header  isAdminPanel/> */}
        <div className="grid grid-cols-[0fr,_1fr] h-screen overflow-auto mx-[5%] xl:mx-auto xl:w-[1400px] hidden-scrollbar bg-white shadow-2xl rounded-3xl mt-20">
          <div className="w-[200px] xl:w-[300px]">
            <Sidebar />
          </div>
          <div className="ml-10 w-[80%] xl:w-[1060px]">
            <Outlet />
          </div>
        </div>
      </main>
    );
  }
};

export default Layout;
