import React from "react";
import Snippet from "../SnippetIcon/Snippet";

const TextFiled = ({
  className,
  label,
  placeholder,
  variant,
  snippet,
  SnippetText,
  ...restProps
}) => {
  return (
    <>
      <Snippet
        variant={"label"}
        label={label}
        text={SnippetText}
        snippet={snippet}
      />
      {variant === "large" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={`bg-pastalblue focus:border focus:border-brightblue placeholder-midgray text-offblack text-base font-ibmplex font-normal tracking-[0.25px] normal-case  rounded-lg lg:h-[53px] lg:w-[468px] block  p-2.5  ${className}`}
          />
        </>
      )}
      {variant === "medium" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={`focus:border focus:border-primaryblue placeholder-lightgray placeholder:font-ptsans placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-[300px] h-[46px] sm:w-[350px] sm:h-[50px] md:w-[370px] md:h-[54px] lg:w-[370px] lg:h-[56px] block  p-2.5  ${className}`}
          />
        </>
      )}
      {variant === "small" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={`focus:border focus:border-primaryblue placeholder-lightgray placeholder:font-ptsans placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-[270px] h-[44px] sm:w-[290px] sm:h-[48px] md:w-[290px] md:h-[52px] lg:w-[290px] lg:h-[54px] block p-3 ${className}`}

          />
        </>
      )}
      {variant === "exsmall" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={`bg-pastalblue focus:border focus:border-brightblue placeholder-midgray text-offblack text-base font-ibmplex font-normal tracking-[0.25px] normal-case  rounded-lg h-[53px] w-[300px] block  p-2.5  ${className}`}
          />
        </>
      )}
      {variant === "general" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={`bg-pastalblue focus:border focus:border-brightblue placeholder-midgray text-offblack text-base font-ibmplex font-normal tracking-[0.25px] normal-case rounded-lg w-full h-[53px] block  p-2.5  ${className}`}
          />
        </>
      )}
    </>
  );
};

export default TextFiled;
