// import useAuth from "../../Hooks/useAuth";

function generateRandomNumber() {
  return Math.random() * 99999;
}
const useSidebarData = (props) => {
  // const { setAuth } = useAuth();
  return [
    {
      linkName: "Dashboard",
      link: "admin-panel/dashboard",
    },
    {

      linkName: "User Management",
      subMenu: [
        {
          linkName: "Profiles",
        },
        {
          linkName: "Activity",
        },
        {
          linkName: "Logs",
        },
      ],
    },
    {

      linkName: "Posts Management",
      subMenu: [
        {
          linkName: "Overview",
        },
        {
          linkName: "Approvals",
        },
        {
          linkName: "Reviews & Ratings",
        },
      ],
    },
    {
      linkName: "Metrics & Reports",
      subMenu: [
        {
          linkName: "Analytics Dashboards",
            link: "",
          id: generateRandomNumber(),
        },
        {
          linkName: "Exportable Reports",
            link: "",
          id: generateRandomNumber(),
        },
        {
          linkName: "User Analytics",
            link: "",
          id: generateRandomNumber(),
        },
        {
          linkName: "Post Analytics",
            link: "",
          id: generateRandomNumber(),
        },
        {
          linkName: "Financial Metrics",
            link: "",
          id: generateRandomNumber(),
        },
        {
          linkName: "SEO Metrics",
            link: "",
          id: generateRandomNumber(),
        },
        {
          linkName: "API Metrics",
            link: "",
          id: generateRandomNumber(),
        },
        {
          linkName: "Reviews Analytics",
            link: "",
          id: generateRandomNumber(),
        },
        {
          linkName: "System Performance",
            link: "",

          id: generateRandomNumber(),
        },
      ],
    },
  
   
    {
      linkName: "Security & Fraud",
      link: ""
    },
    {
      linkName: "Verification Tools",
      link: ""
    },
    {
      linkName: "Compliance Reporting",
      link: ""
    },
    {
      linkName: "Finance Management",
      link: ""
    },
    {
      linkName: "Reviews & Ratings",
      link: ""
    },
    {
      linkName: "Notification Settings",
      link: ""
    },
    
    {
      linkName: "Integrations & API Management",
      link: ""
    },
    {
      linkName: "Content Management",
      link: ""
    },
    {
      linkName: "SEO Tools",
      link: ""
    },
    {
      linkName: "Marketing Management",
      link: ""
    },
    {
      linkName: "Support Management",
      link: ""
    },
    {
      linkName: "Monitoring",
      link: ""
    },
    {
      linkName: "Data Management",
      link: ""
    },
    {
      linkName: "Settings",
      link: ""
    },
    
    // {
    //   linkName: "Finance",
    //   subMenu: [
    //     {
    //       linkName: "",
    //         link: "",
    //       id: generateRandomNumber(),
    //     },
    //     {
    //       linkName: "",
    //         link: "",
    //       id: generateRandomNumber(),
    //     },
    //   ],
    // },
    

    {
      linkName: "Log out",
      link: "/",
      //   onClick: () => {
      //     localStorage.removeItem("LoginSession");
      //     if (props?.toggleDrawer) {
      //       props.toggleDrawer()
      //     }
      //     setAuth({
      //       user: null,
      //       roles: null,
      //       accessToken: "",
      //     });
      //   },
      //        id: generateRandomNumber(),
    },
  ];
};

export default useSidebarData;
